.App {
  overflow: auto;
  scroll-behavior: smooth;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}
.App-sider {
  color: #fff;
  overflow: hidden;
  height: 100vh;
  position: fixed !important;
  left: 0px;
  top: 0;
  bottom: 0;
  z-index: 2;
  background-color: #1d2531;
}

.siderWidth {
  width: 280px !important;
  max-width: 280px !important;
  min-width: 235px !important;
}
.retractSiderWidth {
  width: 80px !important;
  max-width: 280px !important;
  min-width: 80px !important;
}
.ant-layout .ant-layout-sider {
  transition: all 0.5s;
}
.contentLayout {
  transition: all 0.5s;
}
.App-content {
  min-height: 120px;
  line-height: 120px;
  /*   filter: grayscale(0.8); */
  padding: 0;
  background-color: #f7f9fc;
  background-attachment: fixed;
  background-image: url(./assets/intro.svg);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: margin-left 0.4s;
}
.App-footer {
  text-align: center;
  color: #969eaa;
  background-color: #ffffff;
}

.App-header {
  text-align: center;
  height: 64px;
  padding-inline: 32px;
  line-height: 64px;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
