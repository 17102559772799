.item-content {
  width: 97%;
  margin-top: 20px;
}

.item-content:last-child {
  margin-bottom: 30px;
}

.card {
  display: flex;
}

.card-col {
  width: 100%;
}
.card-item {
  border-radius: 8px 24px 8px 8px;
  cursor: pointer;
  background-color: #f7f9fc;
  padding: 16px 12px 16px 16px;
  margin-bottom: 16px;
  display: block;
  position: relative;
}

.card-item:hover {
  background-color: #ebeff5;
}

.card-item:hover .collect_icon {
  display: block !important;
}

.link-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 55px;
  cursor: pointer;
}

.link-img {
  display: block;
  padding: 8px;
  background: #fff;
  /* border: 1px solid #eee; */
  border-radius: 8px;
  margin: 0 auto;
  float: left;
  box-shadow: 0 4px 8px 0 #1d253112;
  color: #000;
}

.link-img span {
  line-height: 26px !important;
}

.card-name {
  color: #38404d;
  font-weight: 500;
  width: 100%;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 20px;
}

.card-description {
  margin: 2px 0 0 0;
  color: #969eaa;
  width: 100%;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.titleIcon {
  margin-right: 10px;
  color: #1d2b3a;
  font-size: 16px;
}

.collect {
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  border-radius: 4px 24px 4px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(245, 247, 250, 1);
}

.empty {
  text-align: center;
  width: 100%;
  padding-bottom: 15px;
  color: rgba(147, 158, 169, 1);
}

.collect_icon {
  display: none;
  width: 32px;
  height: 32px;
  line-height: 37px;
}
