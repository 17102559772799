.logo {
  padding: 14px 20px;
  background: rgba(255, 255, 255, 0.02);
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
}

.silder-select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80px;
  cursor: pointer;
}

.all-tag {
  padding: 20px;
  overflow: hidden;
  min-width: 280px;
}

.tag-item {
  border: solid 1px rgba(255, 255, 255, 0.06);
  padding: 4px 10px;
  border-radius: 15px;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 999px;
}

.tag-item:hover {
  background-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

.tag {
  background-color: #ffffff10;
  color: #fff;
  font-weight: 500;
}

.selected-tag {
  background-color: #fff;
  color: #1d2531;
  font-weight: 500;
}

.selected-tag:hover {
  background-color: #fff;
  color: #1d2531;
}

.site {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.active-site-text {
  color: #fff;
  font-size: 14px;
  margin-right: 8px;
}

.site-text {
  color: #7a7f86;
  font-size: 14px;
  margin-right: 8px;
}

.site-icon {
  display: flex;
  flex-direction: column;
  font-size: 9px;
  color: #7a7f86;
}

.active-site-icon {
  display: flex;
  flex-direction: column;
  font-size: 9px;
  color: #fff;
}

.site-content {
  width: 240px;
  position: absolute;
  /* height: 200px; */
  background: rgba(34, 41, 53, 1);
  top: 61px;
  left: 20px;
  z-index: 20;
  padding: 20px 10px;
  border-radius: 8px;
  border: solid 1px rgba(255, 255, 255, 0.12);
}

.active-site-item {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 1)
  );
  border: solid 1px rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  color: #000;
  border-radius: 6px;
  padding: 0px 12px;
  margin-bottom: 10px;
  cursor: pointer;
}

.site-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  border: solid 1px rgba(255, 255, 255, 0.2);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.06)
  );
  color: #fff;
  border-radius: 6px;
  padding: 0px 12px;
  margin-bottom: 10px;
  cursor: pointer;
}

.collapsed_block {
  text-align: center;
  margin-top: 20px;
  font-size: 22px;
  position: relative;
}

.collapsed_icon {
  padding: 6px;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 4px;
}

.collapsed_icon:hover {
  background: rgba(255, 255, 255, 0.2);
}

.active_collapsed_icon {
  padding: 5px;
  background: #fff;
  color: #1d2531;
  border-radius: 4px;
  fill: rgba(255, 255, 255, 0.85);
}

.collapsed_tag {
  position: fixed;
  width: 408px;
  left: 86px;
  top: 50px;
  background-color: #1d2531;
  padding: 20px 20px 12px 20px;
  border-radius: 8px;
  text-align: left;
}
